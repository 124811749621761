@import '../../../styles/shared';
@import '~@radargovernamental/orbit-style/dist/styles/tools/mq';

.main {
  background: $main-background;
  margin: $header-height 0 0 $navBar-width--collapsed;
  min-height: calc(100% - #{$header-height});

  &--full-width {
    margin-left: 0;
  }

  @include mq($until: desktop) {
    margin: $header-height-mobile 0 0 0;
    min-height: calc(100% - #{$header-height-mobile});
  }
}
