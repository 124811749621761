/** Spaces **/

$header-height: 88px;
$header-height-mobile: 60px;

$navBar-width--collapsed: 88px;
$navBar-width--expanded: 300px;

/** Colors **/
$layout-background: #fff;
$layout-border-color: #ECEFF1;

$main-background: #FAFBFC;

