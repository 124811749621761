@import '~@radargovernamental/orbit-style/dist/styles/settings/spaces';
@import '~@radargovernamental/orbit-style/dist/styles/tools/mq';
@import '../../../styles/shared';

.header {
  background: rgba(30, 66, 185, 1); /* Old browsers */
  background: -moz-linear-gradient(left, rgba(30, 66, 185, 1) 0%, rgba(7,53,125,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(30, 66, 185, 1) 0%,rgba(7,53,125,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(30, 66, 185, 1) 0%,rgba(7,53,125,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1E42B9', endColorstr='#07357d',GradientType=1 ); /* IE6-9 */

  color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: $header-height;
  display: flex;
  flex-direction: row;
  z-index: 9999;

  @include mq($until: desktop) {
    height: $header-height-mobile;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $header-height;
    width: $navBar-width--collapsed;
    text-align: center;
    border-right: 1px solid rgba(255, 255, 255, .1);
    flex-shrink: 0;

    button {
      display: none;
      background: none;
      border: none;
      height: $header-height-mobile;
      width: $navBar-width--collapsed;
    }

    @include mq($until: desktop) {
      height: $header-height-mobile;
      width: $header-height-mobile;

      a {
        display: none;
      }

      button {
        display: inline-block;
      }
    }
  }

  &__search {
    width: 100%;
    input {
      background: none;
      color: #fff;
      font-size: 1rem;
      border: none;
      height: $header-height;
      padding: 0 30px;
      width: 100%;


      @include mq($until: desktop) {
        height: $header-height-mobile;
        padding: 0 12px;
      }

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, .7);
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: rgba(255, 255, 255, .7);
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: rgba(255, 255, 255, .7);
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: rgba(255, 255, 255, .7);
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    text-align: right;
    padding-right: 20px;
    align-items: center;
    & > * {
      margin: 0 8px;
    }
  }
}
