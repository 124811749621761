@import '~@radargovernamental/orbit-style/dist/styles/settings/colors';
@import '~@radargovernamental/orbit-style/dist/styles/tools/mq';

.companyLogo {
  position: relative;

  @include mq($until: desktop) {
    display: none;
  }

  img {
    width: 70px;
    padding: 15px;
  }

  &::after, &::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: -1;
  }

  &::after {
    background-color: $color-white;
    width: 65px;
    height: 65px;
  }

  &::before {
    width: 73px;
    height: 73px;
    background-color: darken($color-primary, 15%);
  }


}
