@import '~@radargovernamental/orbit-style/dist/styles/settings/colors';
@import '~@radargovernamental/orbit-style/dist/styles/settings/type';
@import '~@radargovernamental/orbit-style/dist/styles/settings/spaces';

.toastr {
  font-family: $font-text;
  font-size: 1rem;
  line-height: 1.5rem;
  z-index: 100000;

  :global {
    .Toastify__toast--info {
      background: $color-primary;
    }
    .Toastify__toast--success {
      background: $color-success;
    }
    .Toastify__toast--warning {
      background: $color-warning;
    }
    .Toastify__toast--error {
      background: $color-danger;
    }
  }

  &__body {
    padding-left: 12px;
    padding-right: 12px;
  }

  &__progress {
    height: 2px;
  }
}
