.avatar {
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 5px;
  border: none;
  background-position: center center;
  background-size: 100%;
  transition: background-size 0.2s ease-in;
  vertical-align: -18px;

  &:hover, &:focus {
    background-size: 120%;
  }

  &--lg {
    width: 96px;
    height: 96px;
    border-radius: 50%;
  }

  &--md {
    width: 48px;
    height: 48px;
    border-radius: 5px;
  }

  &--sm {
    width: 30px;
    height: 30px;
    border-radius: 5px;
  }

  &--xs {
    width: 20px;
    height: 20px;
    border-radius: 5px;
  }
}
