@import '~@radargovernamental/orbit-style/dist/styles/settings/colors';
@import '~@radargovernamental/orbit-style/dist/styles/tools/mq';

@import '../../../styles/shared';

.navBar {
  position: fixed;
  top: $header-height;
  bottom: 0;
  left: 0;
  width: $navBar-width--collapsed;
  background: $layout-background;
  border-right: 1px solid $layout-border-color;
  overflow-x: hidden;
  transition: all 0.2s ease-in-out;
  z-index: 9999;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  @include mq($until: desktop) {
    top: $header-height-mobile;
  }

  &--expanded, &--visible {
    width: $navBar-width--expanded;
  }

  &__list {
    li {
      margin: 40px 0;
      width: $navBar-width--collapsed;
      position: relative;
      text-align: center;

      @include mq($until: desktop) {
        margin: 24px 0;
      }
    }

    a {
      display: block;
      text-decoration: none;
      color: $color-text;
    }

    &__text {
      display: inline-block;
      position: absolute;
      left: $navBar-width--collapsed;
      top: 5px;
      width: 200px;
      text-align: left;
    }

    .iconNormal {
      display: inline-block;
    }
    .iconHover {
      display: none;
    }

    a:hover, a.active {
      .iconNormal {
        display: none;
      }
      .iconHover {
        display: inline-block;
      }
    }
  }

  @include mq($until: desktop) {
    left: -100%;
    width: $navBar-width--collapsed;

    &--visible {
      left: 0;
      width: $navBar-width--expanded;
    }
  }

  &__toggler {
    border: none;
    background: none;
    display: block;
    width: 40px;
    height: 40px;
    margin: 10px auto;
    padding: 0 4px;
    &:hover {
      cursor: pointer;
    }

    @include mq($until: desktop) {
      display: none;
    }
  }
}
