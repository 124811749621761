@import '~@radargovernamental/orbit-style/dist/styles/generic/reset';
@import '~@radargovernamental/orbit-style/dist/styles/generic/box-sizing';
@import '~@radargovernamental/orbit-style/dist/styles/settings/_global.scss';
@import '~@radargovernamental/orbit-style/dist/styles/settings/_colors.scss';
@import '~@radargovernamental/orbit-style/dist/styles/settings/_type.scss';

body {
  background: url(./screens/Auth/background.png) $color-brand-primary center center;
  background-size: cover;

  margin: 0;
  padding: 0;
  font-family: $font-text;
}

a, a:visited {
  color: $color-primary;
}

#root {
  height: 100vh;
  display: flex;
}

hr {
  height: 1px;
  border: none;
  background: #ECEFF1;
  margin: 24px 0;
}

em {
  font-style: italic;
}
b {
  font-weight: 600;
}


.ql-align-right {
  text-align: right;
}
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}  
.ql-align-justify {
  text-align: justify;
}

.category-100 span{
  background-color: #F2A46B !important;
}  

.category-200 span{
  background-color: #D07837 !important;
}  

.category-300 span{
  background-color: #A55214 !important;
}  

.category-400 span{
  background-color: #703000 !important;
}  
.new-document span{
  background-color: #ffd500 !important;
}  