@import '~@radargovernamental/orbit-style/dist/styles/settings/colors';
@import '~@radargovernamental/orbit-style/dist/styles/tools/mq';
@import '../../../../styles/shared';


.userActions {
  display: flex;
  margin: 0 15px;
  position: relative;
  align-items: center;
  line-height: $header-height;
  white-space: nowrap;

  @include mq($until: tablet) {
    line-height: $header-height-mobile;
  }

  p {
    flex: 1;
  }

  &__name {
    @include mq($until: tablet) {
      display: none;
    }
  }

  &__dropdown {
    position: absolute;
    bottom: 100%;
    opacity:0;
    left: 0;
    right: 0;
    border: 1px solid lighten($color-text-light, 30%);
    border-radius: 0 0 5px 5px;

    li + li {
      border-top: 1px solid lighten($color-text-light, 30%);
    }

    &__item {
      background: #fff;
      border: none;
      color: $color-text;
      display: block;
      font-size: 1rem;
      height: 48px;
      line-height: 48px;
      padding: 0 20px;
      width: 100%;
      text-align: left;
      text-decoration: none;

      &:hover, &:focus {
        background: #f1f1f1;
        cursor: pointer;
      }
    }
  }

  &:hover, &:focus, &--focused {
    .userActions__dropdown {
      bottom: auto;
      top: 100%;
      opacity: 1;
    }
  }

  &__chevrown {
    display: inline-block;
    margin: 0 30px;
  }
}
